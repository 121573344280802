;(function (angular, undefined) {
   'use strict'

   angular.module('azure.data').factory('orderData', orderData)

   function orderData($q, $http, AzureAPI, CacheFactory, config, util, rewardData) {
      //================================================================================
      // Cache
      //================================================================================

      var _cache = CacheFactory.createCache('orders')

      // cache key variables, used for building cache keys (each should be unique)
      var _keyUnresolvedOrderIds = 0
      var _keyResolvedOrderIds = 1
      var _keyResolvedOrderCount = 2
      var _keyOrder = 3
      var _keyOrderIdsByDropTrip = 5
      var _keyOrderCountByDropTrip = 6
      var _keyOrderLine = 7
      var _keyOrderLineIds = 8
      var _keyOrderFees = 9
      var _keyParcelCarrierEstimates = 10
      var _keyPromoCode = 11
      var _keyD2hEstimates = 12

      // local storage cache for orders
      var _localStorage = CacheFactory.createCache('localOrders', {
         storageMode: 'localStorage',
         maxAge: Number.MAX_VALUE,
      })

      // cache key variables, used for building cache keys (each should be unique)
      var _keyLocalOrderLines = 0
      var _keyLocalActiveOrderId = 1
      var _keyLocalLastDestination = 2
      var _keyLocalLastPaymentMethodId = 3
      var _keyLocalPromoCode = 4

      // In-memory active order id
      var _activeOrderId

      var getCacheKey = util.joinArgs
      var retryOnce = util.asyncRetryOnce

      function cacheOrder(order) {
         // Note: parcel carrier orders have a trip, but we don't care about it because the trip cutoff doesn't
         // determine whether a parcel carrier order is shoppable. A parcel carrier order is shoppable if it is open.
         // Removing the trip for parcel carrier orders has the following advantages:
         // - Avoids needlessly fetching the trip data when it is not used
         // - If an open parcel carrier order on a trip past cutoff is saved, the API will automatically bump it
         //   forward to the next trip
         if (order.parcelCarrierDeliveryAddress) {
            delete order.trip
         }
         return _cache.put(getCacheKey(_keyOrder, order.id), order)
      }

      function cacheOrders(orders) {
         orders.forEach(cacheOrder)
         return orders
      }

      function cacheRemoveUser(userId) {
         var unresolvedOrderIds = _cache.remove(getCacheKey(_keyUnresolvedOrderIds, userId))
         if (unresolvedOrderIds) {
            $q.resolve(unresolvedOrderIds).then(cacheRemoveOrders)
         }
         // TODO: remove all pages of resolvedOrderIds
      }

      function cacheRemoveDropTrip(dropId, tripId) {
         _cache.remove(getCacheKey(_keyOrderIdsByDropTrip, dropId, tripId))
         _cache.remove(getCacheKey(_keyOrderCountByDropTrip, dropId, tripId))
      }

      function cacheRemoveOrder(orderId) {
         var order = _cache.remove(getCacheKey(_keyOrder, orderId))
         if (order) {
            cacheRemoveDropTrip(order.drop, order.trip)
            if (order.orderPromoCodeIds) {
               order.orderPromoCodeIds.forEach(function (promoCodeId) {
                  _cache.remove(getCacheKey(_keyPromoCode, orderId, promoCodeId))
               })
            }
         }
         _cache.remove(getCacheKey(_keyParcelCarrierEstimates, orderId))
         _cache.remove(getCacheKey(_keyOrderFees, orderId))
         var orderLineIds = _cache.remove(getCacheKey(_keyOrderLineIds, orderId))
         if (orderLineIds) {
            $q.resolve(orderLineIds).then(cacheRemoveOrderLines)
         }
      }

      function cacheRemoveOrders(orderIds) {
         orderIds.forEach(cacheRemoveOrder)
      }

      function cacheAddUnresolvedOrderId(userId, orderId) {
         var unresolvedOrderIds = _cache.get(getCacheKey(_keyUnresolvedOrderIds, userId))
         if (unresolvedOrderIds !== undefined) {
            $q.resolve(unresolvedOrderIds).then(function (ids) {
               ids.push(orderId)
            })
         }
      }

      function cacheRemoveUnresolvedOrderId(userId, orderId) {
         var unresolvedOrderIds = _cache.get(getCacheKey(_keyUnresolvedOrderIds, userId))
         if (unresolvedOrderIds !== undefined) {
            $q.resolve(unresolvedOrderIds).then(function (ids) {
               var index = ids.indexOf(orderId)
               if (index > -1) {
                  ids.splice(index, 1)
               }
            })
         }
      }

      function cacheOrderLine(orderLine, overwrite) {
         var cacheKey = getCacheKey(_keyOrderLine, orderLine.id)
         if (!_cache.get(cacheKey) || overwrite) {
            _cache.put(cacheKey, orderLine)
         }
      }

      function cacheOrderLines(orderLines, overwrite) {
         orderLines.forEach(function (orderLine) {
            cacheOrderLine(orderLine, overwrite)
         })
      }

      function cacheRemoveOrderLine(orderLineId) {
         _cache.remove(getCacheKey(_keyOrderLine, orderLineId))
      }

      function cacheRemoveOrderLines(orderLineIds) {
         orderLineIds.forEach(cacheRemoveOrderLine)
      }

      function cacheAddOrderLineId(orderId, orderLineId) {
         var orderLineIds = _cache.get(getCacheKey(_keyOrderLineIds, orderId))
         if (orderLineIds) {
            $q.resolve(orderLineIds).then(function (ids) {
               ids.push(orderLineId)
            })
         }
      }

      function cacheRemoveOrderLineId(orderId, orderLineId) {
         var orderLineIds = _cache.get(getCacheKey(_keyOrderLineIds, orderId))
         if (orderLineIds) {
            $q.resolve(orderLineIds).then(function (ids) {
               var index = ids.indexOf(orderLineId)
               if (index > -1) {
                  ids.splice(index, 1)
               }
            })
         }
      }

      function handleOrderLineChangeCacheClearing(changes, orderId) {
         _cache.remove(getCacheKey(_keyParcelCarrierEstimates, orderId))

         // if parcel carrier services were removed, clear the cached order
         if (changesIncludeParcelCarrierServicesRemoved(changes)) {
            _cache.remove(getCacheKey(_keyOrder, orderId))
         }

         maybeClearCachedPromoCodes(orderId, changes)

         // changes to orderLines may change the fees of an order
         // TODO: will the API return order-fee removal as a change? It probably should
         // Then we can do this only if the API says it removed order fees
         _cache.remove(getCacheKey(_keyOrderFees, orderId))
      }

      function clearLocalLastPaymentMethodId(userId) {
         _localStorage.remove(getCacheKey(_keyLocalLastPaymentMethodId, userId))
      }

      function maybeClearCachedPromoCodes(orderId, changes) {
         if (!changes || !changes.length) {
            return
         }

         var relevantChanges = changes.filter(function (change) {
            // Change types that require clearing the respective promo-code's cache
            return [
               'order-promo-code-invalidated',
               'order-promo-code-validated',
               'order-promo-code-discount-amount-changed',
               'order-promo-code-new-invalid-reason-type',
            ].includes(change.type)
         })

         if (relevantChanges) {
            relevantChanges.forEach(function (change) {
               _cache.remove(getCacheKey(_keyPromoCode, orderId, change.orderPromoCodeId))
            })
         }
      }

      //================================================================================
      // Order Statuses
      //================================================================================

      var _statuses = Object.freeze({
         // Unresolved statuses
         open: 'open',
         placed: 'placed',
         // Resolved statuses
         confirmed: 'confirmed',
         shipped: 'shipped',
         deliveredToDrop: 'delivered-to-drop',
         cancelled: 'cancelled',
         outForHomeDelivery: 'out-for-home-delivery',
         deliveredToHome: 'delivered-to-home',
      })

      var _unresolvedStatusStrings = [_statuses.open, _statuses.placed]
      var _resolvedStatusStrings = [
         _statuses.confirmed,
         _statuses.shipped,
         _statuses.deliveredToDrop,
         _statuses.cancelled,
         _statuses.outForHomeDelivery,
         _statuses.deliveredToHome,
      ]

      //================================================================================
      // Helper Functions
      //================================================================================

      function changesIncludeParcelCarrierServicesRemoved(changes) {
         if (
            changes &&
            changes.find(function (change) {
               return change.type === 'parcel-carrier-services-removed'
            })
         ) {
            return true
         }
         return false
      }

      function updateLocalLastUsed(userId, orderData) {
         // If the orderData includes a destination, save it to local storage
         if (orderData.drop || orderData.parcelCarrierDeliveryAddress) {
            var lastDestination = orderData.drop
               ? {drop: orderData.drop}
               : {parcelCarrierDeliveryAddress: orderData.parcelCarrierDeliveryAddress}

            _localStorage.put(getCacheKey(_keyLocalLastDestination, userId), lastDestination)
         }
         // If the orderData includes a payment method, save it to local storage
         if (orderData['checkout-payment']) {
            var lastPaymentMethodId = orderData['checkout-payment']['payment-method']

            _localStorage.put(getCacheKey(_keyLocalLastPaymentMethodId, userId), lastPaymentMethodId)
         }
      }

      //================================================================================
      // Orders: Create
      //================================================================================

      function createOrder(userId, createOrderData) {
         var order = {
            customer: userId,
            status: _statuses.open,
         }
         order = angular.extend({}, createOrderData, order)
         return retryOnce(AzureAPI.order.create, order)
            .then(util.cleanItem)
            .then(cacheOrder)
            .then(function (createdOrder) {
               updateLocalLastUsed(userId, createdOrder)
               cacheAddUnresolvedOrderId(userId, createdOrder.id)
               return createdOrder.id
            })
      }

      //================================================================================
      // Orders: Read
      //================================================================================

      var _localOrder = {
         id: 'local',
         status: _statuses.open,
      }

      function getOrder(orderId, bypassCache) {
         if (!orderId) {
            return $q.resolve()
         } else if (orderId === _localOrder.id) {
            return $q.resolve(_localOrder)
         }

         var cacheKey = getCacheKey(_keyOrder, orderId)

         var order = _cache.get(cacheKey)
         if (!order || bypassCache) {
            order = retryOnce(AzureAPI.order.get, {
               id: orderId,
               computed: 'cases',
            })
               .then(util.cleanItem)
               .then(cacheOrder)

            // cache the promise
            _cache.put(cacheKey, order, {
               storeOnResolve: false,
            })
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(order)
      }

      function getOrdersByIds(orderIds) {
         return $q.map(orderIds, function (orderId) {
            return getOrder(orderId)
         })
      }

      function getActiveOrderId(userId) {
         if (_activeOrderId === undefined) {
            if (userId) {
               _activeOrderId = _localStorage.get(getCacheKey(_keyLocalActiveOrderId, userId))
            } else {
               _activeOrderId = _localOrder.id
            }
         }
         return $q.resolve(_activeOrderId)
      }

      function getActiveOrder(userId) {
         return getActiveOrderId(userId).then(getOrder)
      }

      function getLocalLastDestination(userId) {
         return $q.resolve(_localStorage.get(getCacheKey(_keyLocalLastDestination, userId)))
      }

      function getLocalLastPaymentMethodId(userId) {
         return $q.resolve(_localStorage.get(getCacheKey(_keyLocalLastPaymentMethodId, userId)))
      }

      function getUnresolvedOrderIds(userId, bypassCache) {
         if (!userId) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keyUnresolvedOrderIds, userId)

         var unresolvedOrderIds = _cache.get(cacheKey)
         if (!unresolvedOrderIds || bypassCache) {
            unresolvedOrderIds = retryOnce(AzureAPI.order.query, {
               'filter-person': userId,
               status: _unresolvedStatusStrings.join(),
               limit: config.apiLimit,
            })
               .then(cacheOrders)
               .then(util.mapToIds)

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, unresolvedOrderIds)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(unresolvedOrderIds)
      }

      function getUnresolvedOrders(userId, bypassCache) {
         return getUnresolvedOrderIds(userId, bypassCache).then(getOrdersByIds)
      }

      function getResolvedOrderIds(userId, start, limit) {
         if (!userId) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keyResolvedOrderIds, userId, start, limit)

         var resolvedOrderIds = _cache.get(cacheKey)
         if (!resolvedOrderIds) {
            var params = {
               'filter-person': userId,
               status: _resolvedStatusStrings.join(),
               computed: 'cases',
               limit: limit || config.apiLimit,
            }
            if (start) {
               params.start = start
            }

            resolvedOrderIds = retryOnce(AzureAPI.order.query, params).then(cacheOrders).then(util.mapToIds)

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, resolvedOrderIds)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(resolvedOrderIds)
      }

      function getResolvedOrders(userId, start, limit) {
         return getResolvedOrderIds(userId, start, limit).then(getOrdersByIds)
      }

      function getResolvedOrderCount(userId) {
         if (!userId) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keyResolvedOrderCount, userId)

         var resolvedOrderCount = _cache.get(cacheKey)
         if (resolvedOrderCount === undefined) {
            resolvedOrderCount = retryOnce(AzureAPI.order.count, {
               'filter-person': userId,
               status: _resolvedStatusStrings.join(),
            }).then(function (response) {
               return response.resource.count
            })

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, resolvedOrderCount)
         }

         return $q.resolve(resolvedOrderCount)
      }

      function getOrderIdsByDropTrip(dropId, tripId, bypassCache) {
         if (!dropId || !tripId) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keyOrderIdsByDropTrip, dropId, tripId)
         var orderIds = _cache.get(cacheKey)

         if (!orderIds || bypassCache) {
            orderIds = retryOnce(AzureAPI.order.query, {
               drop: dropId,
               trip: tripId,
               limit: config.apiLimit,
            })
               .then(cacheOrders)
               .then(util.mapToIds)

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, orderIds)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(orderIds)
      }

      function getOrdersByDropTrip(dropId, tripId, bypassCache) {
         return getOrderIdsByDropTrip(dropId, tripId, bypassCache).then(getOrdersByIds)
      }

      function getOrderCountByDropTrip(dropId, tripId, bypassCache) {
         var orderIds = _cache.get(getCacheKey(_keyOrderIdsByDropTrip, dropId, tripId))
         if (orderIds) {
            return $q.resolve(orderIds.length)
         }

         var cacheKey = getCacheKey(_keyOrderCountByDropTrip, dropId, tripId)

         var count = _cache.get(cacheKey)
         if (count === undefined || bypassCache) {
            count = retryOnce(AzureAPI.order.count, {
               drop: dropId,
               trip: tripId,
            }).then(function (response) {
               return response.resource.count
            })

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, count)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(count)
      }

      function getParcelCarrierEstimates(orderId, address, bypassCache) {
         if (!orderId || orderId === _localOrder.id || !address || Object.keys(address).length === 0) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keyParcelCarrierEstimates, orderId)

         var estimates = _cache.get(cacheKey)
         if (!estimates || bypassCache) {
            estimates = {}
            _cache.put(cacheKey, estimates)
         }

         var estimateKey = getCacheKey(
            address['postal-code'],
            address.locality,
            address['street-address'],
            address['extended-address']
         )

         if (Array.isArray(estimates[estimateKey])) {
            // check if we need to throw away the cached shipping estimates
            var now = new Date()
            var estimatesExpired =
               estimates[estimateKey].length === 0 ||
               estimates[estimateKey].some(function (estimate) {
                  return new Date(estimate.expires) < now
               })

            if (estimatesExpired) {
               delete estimates[estimateKey]
            }
         }

         if (!estimates[estimateKey]) {
            estimates[estimateKey] = retryOnce(AzureAPI.order.parcelCarrierEstimates, {
               id: orderId,
               'postal-code': address['postal-code'],
               locality: address.locality,
               'street-address': address['street-address'],
               'extended-address': address['extended-address'],
            })
               .then(util.cleanItem)
               .then(function (orderPostalCodeEstimates) {
                  util.sortByProperty('cost', orderPostalCodeEstimates)
                  estimates[estimateKey] = orderPostalCodeEstimates
                  return orderPostalCodeEstimates
               })
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return (
            $q
               .resolve(estimates[estimateKey])
               // ================================================================
               // TODO: [CLIMATE_NAMING_REFACTOR] Remove once the API is updated (can go back to just `return $q.resolve(estimates[estimateKey])`)
               .then(function (resolvedEstimates) {
                  if (resolvedEstimates && resolvedEstimates.length) {
                     return resolvedEstimates.map(function (estimate) {
                        if (estimate.climate === 'chilled/frozen') {
                           estimate.climate = 'chilled'
                        } else if (estimate.climate === 'dry') {
                           estimate.climate = 'roomTemp'
                        }
                        return estimate
                     })
                  } else {
                     return resolvedEstimates
                  }
               })
         )
         // END TODO
         // ================================================================
      }

      function getSingleD2hQualifyingEstimate(orderId, address, dropId, tripId, bypassCache) {
         // Guard for missing or invalid parameters
         if (!orderId || orderId === _localOrder.id || !address || !address.id || !dropId || !tripId) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keyD2hEstimates, orderId)

         var estimates = _cache.get(cacheKey)
         if (!estimates || bypassCache) {
            estimates = {}
            _cache.put(cacheKey, estimates)
         }

         var estimateKey = getCacheKey(
            address.locality,
            address['postal-code'],
            address['street-address'],
            address['extended-address'],
            address.region,
            dropId,
            tripId
         )

         if (!estimates[estimateKey]) {
            estimates[estimateKey] = retryOnce($http, {
               method: 'GET',
               withCredentials: true,
               url:
                  config.beehiveApiV2 +
                  '/orders/orders/' +
                  orderId +
                  '/home-delivery-estimate?addressId=' +
                  address.id +
                  '&dropId=' +
                  dropId +
                  '&tripId=' +
                  tripId,
            }).then(function (response) {
               // Note that we're never returning a non-qualifying estimate
               var d2hEstimate = response.data && response.data.qualifies ? response.data : undefined

               estimates[estimateKey] = d2hEstimate

               return d2hEstimate
            })
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(estimates[estimateKey])
      }

      function getD2hQualifyingEstimates(orderId, address, excludeNonQualifying, bypassCache) {
         if (!orderId || orderId === _localOrder.id) {
            return $q.resolve()
         }

         var cacheKey = getCacheKey(_keyD2hEstimates, orderId)

         var estimates = _cache.get(cacheKey)
         if (!estimates || bypassCache) {
            estimates = {}
            _cache.put(cacheKey, estimates)
         }

         var estimateKey = getCacheKey(
            address.locality,
            address['postal-code'],
            address['street-address'],
            address['extended-address'],
            address.region
         )

         if (!estimates[estimateKey]) {
            estimates[estimateKey] = retryOnce($http, {
               method: 'GET',
               withCredentials: true,
               url:
                  config.beehiveApiV2 +
                  '/orders/orders/' +
                  orderId +
                  '/home-delivery-estimates?addressId=' +
                  address.id,
            }).then(function (response) {
               if (!Array.isArray(response.data) && !response.data.qualifies) {
                  return []
               }

               var d2hEstimates = response.data

               if (d2hEstimates && excludeNonQualifying) {
                  d2hEstimates = d2hEstimates.filter(function (estimate) {
                     return estimate.qualifies
                  })
               }

               util.sortByProperty('charge', d2hEstimates)

               estimates[estimateKey] = d2hEstimates

               return d2hEstimates
            })
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(estimates[estimateKey])
      }

      function getOrderFees(orderId, bypassCache) {
         if (!orderId) {
            return $q.resolve()
         } else if (orderId === _localOrder.id) {
            return $q.resolve([])
         }

         var cacheKey = getCacheKey(_keyOrderFees, orderId)

         var orderFees = _cache.get(cacheKey)
         if (!orderFees || bypassCache) {
            orderFees = retryOnce(AzureAPI['order-fee'].query, {
               order: orderId,
            })

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, orderFees)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(orderFees)
      }

      function getLocalOrderId() {
         return $q.resolve(_localOrder.id)
      }

      //================================================================================
      // Orders: Update
      //================================================================================

      function maybeTranslateError(error) {
         var errorMessage = error.data && error.data.message
         if (errorMessage) {
            if (errorMessage.includes('choose another payment method')) {
               error.message = errorMessage.replace(
                  /^checkout-payment\.payment-method \d+/g,
                  'The selected payment method'
               )
               error.invalidPaymentMethod = true
            } else if (errorMessage.includes('after cutoff')) {
               error.message = 'This order is past cutoff.'
            } else if (errorMessage.includes('outside the US')) {
               // Error - Trying to ship to non-US address (which Azure doesn't currently support)
               error.message =
                  "It looks like your address isn't in the US, which means we can't ship to it. Please use an address in the US or <a ui-sref=\"cmsTopLevelPage({slug: 'support'})\">contact us</a> for assistance."
            }
         }
         return $q.reject(error)
      }

      // TODO: [CLIMATE_NAMING_REFACTOR] Remove this function and its usage once the API is updated
      function maybeUpdateParcelCarrierServicesPropsForOrderUpdateRequest(orderData) {
         // If the orderData includes parcel-carrier-services, update the relevant properties to match what the API expects.
         if (orderData['parcel-carrier-services']) {
            if (orderData['parcel-carrier-services'].chilled) {
               orderData['parcel-carrier-services']['chilled/frozen'] = orderData['parcel-carrier-services'].chilled
               delete orderData['parcel-carrier-services'].chilled
            }
            if (orderData['parcel-carrier-services'].roomTemp) {
               orderData['parcel-carrier-services'].dry = orderData['parcel-carrier-services'].roomTemp
               delete orderData['parcel-carrier-services'].roomTemp
            }
         }
         return orderData
      }

      function tryUpdateOrder(orderId, orderData) {
         if (orderId === _localOrder.id) {
            return $q.reject()
         }

         return getOrder(orderId).then(function (order) {
            var updatedOrder = maybeUpdateParcelCarrierServicesPropsForOrderUpdateRequest(
               angular.extend({}, order, orderData)
            )
            // do not allow changing the id
            updatedOrder.id = orderId
            return retryOnce(
               AzureAPI.order.save,
               {
                  save: false,
               },
               updatedOrder
            )
               .then(util.cleanItem)
               .catch(maybeTranslateError)
         })
      }

      function updateOrder(orderId, orderData) {
         if (orderId === _localOrder.id) {
            return $q.reject()
         }

         return getOrder(orderId).then(function (order) {
            var updatedOrder = maybeUpdateParcelCarrierServicesPropsForOrderUpdateRequest(
               angular.extend({}, order, orderData)
            )

            var isDropOrTripChange = orderData.hasOwnProperty('drop') || orderData.hasOwnProperty('trip')
            var isParcelCarrierServicesChange = orderData.hasOwnProperty('parcel-carrier-services')

            // do not allow changing the id
            updatedOrder.id = orderId

            // If order status changes to open from placed, reset the rewards allocation
            // to 0, as a rewards allocation on an open order is meaningless.
            if (updatedOrder.status === _statuses.open) {
               updatedOrder['rewards-allocation'] = 0
            }

            return retryOnce(AzureAPI.order.save, updatedOrder)
               .then(function (response) {
                  // the response has the order nested in the order property
                  var updatedOrder = response.order

                  // If drop shipment changed, some cache clearing is in order
                  if (isDropOrTripChange) {
                     if (order.drop) {
                        cacheRemoveDropTrip(order.drop, order.trip)
                     }
                     if (updatedOrder.drop) {
                        cacheRemoveDropTrip(updatedOrder.drop, updatedOrder.trip)
                     }
                  }

                  // If changes happened on the server as a result of this update, clear cached order
                  // TODO: maybe the cache clearing here could get more granular depending on type of change
                  if (response.changes) {
                     cacheRemoveOrder(orderId)
                  } else {
                     response.changes = []

                     if (isDropOrTripChange || isParcelCarrierServicesChange) {
                        // TODO: will the API return order-fee removal as a change? It probably should
                        // Then we can do this only if the API says it removed order fees
                        _cache.remove(getCacheKey(_keyOrderFees, order.id))
                     }
                  }

                  // Identify other changes the caller needs to be notified of
                  if (
                     (order.status === _statuses.placed || updatedOrder.status === _statuses.placed) &&
                     order['rewards-allocation'] !== updatedOrder['rewards-allocation']
                  ) {
                     response.changes.push({
                        type: 'rewards-balance-updated',
                     })
                     // Clear cached reward data for this user
                     rewardData.clearUserCache(response.order.customer)
                  }

                  updateLocalLastUsed(order.customer, orderData)

                  cacheOrder(updatedOrder)
                  return response
               })
               .catch(maybeTranslateError)
         })
      }

      function setActiveOrderId(userId, orderId) {
         _activeOrderId = orderId
         if (userId) {
            _localStorage.put(getCacheKey(_keyLocalActiveOrderId, userId), orderId)
         }
         return $q.resolve(_activeOrderId)
      }

      function lateUpdateToD2hDelivery(orderId, dropId, tripId, d2hAddressId) {
         return retryOnce($http, {
            method: 'PUT',
            url: config.beehiveApiV2 + '/orders/orders/' + orderId + '/home-delivery-update-shipment',
            withCredentials: true,
            data: {
               drop_id: dropId,
               trip_id: tripId,
               drop_to_home_delivery_address_id: d2hAddressId,
            },
         })
      }

      //================================================================================
      // Orders: Delete
      //================================================================================

      function deleteOrder(orderId) {
         return getOrder(orderId).then(function (order) {
            return retryOnce(AzureAPI.order.delete, {
               id: orderId,
            }).then(function () {
               cacheRemoveOrder(orderId)
               cacheRemoveUnresolvedOrderId(order.customer, orderId)
               getActiveOrderId(order.customer).then(function (activeOrderId) {
                  if (activeOrderId === orderId) {
                     setActiveOrderId(order.customer)
                  }
               })
               return orderId
            })
         })
      }

      //================================================================================
      // OrderLines: Create
      //================================================================================

      function createLocalOrderLine(orderLine) {
         return getLocalOrderLines().then(function (localOrderLines) {
            orderLine.id = 1
            if (localOrderLines.length) {
               // if there are existing local order lines, make sure this new one has the max id.
               orderLine.id += Math.max.apply(null, util.mapToIds(localOrderLines))
            }
            localOrderLines.push(orderLine)
            _localStorage.put(_keyLocalOrderLines, localOrderLines)
            return $q.resolve({'order-line': orderLine})
         })
      }

      function createOrderLine(orderId, productCode, quantity) {
         var newOrderLine = {
            order: orderId,
            'packaged-product': productCode,
            'quantity-ordered': quantity,
         }
         if (orderId === _localOrder.id) {
            return createLocalOrderLine(newOrderLine)
         }
         return retryOnce(AzureAPI['order-line'].create, newOrderLine)
            .then(util.cleanItem)
            .then(function (response) {
               var createdOrderLine = response['order-line']
               cacheOrderLine(createdOrderLine)
               cacheAddOrderLineId(orderId, createdOrderLine.id)
               handleOrderLineChangeCacheClearing(response.changes, orderId)
               return response
            })
            .catch(maybeTranslateError)
      }

      //================================================================================
      // OrderLines: Read
      //================================================================================

      function getOrderLine(orderLineId) {
         var cacheKey = getCacheKey(_keyOrderLine, orderLineId)

         var orderLine = _cache.get(cacheKey)
         if (!orderLine) {
            orderLine = retryOnce(AzureAPI['order-line'].get, {
               id: orderLineId,
            }).then(util.cleanItem)

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, orderLine)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(orderLine)
      }

      function getOrderLinesByIds(orderLineIds) {
         return $q.map(orderLineIds, function (orderlineId) {
            return getOrderLine(orderlineId)
         })
      }

      function getOrderLinesPage(orderId, start, limit) {
         return retryOnce(AzureAPI['order-line'].query, {
            order: orderId,
            limit: limit,
            start: start,
         })
      }

      function getOrderLineIds(orderId, bypassCache) {
         var cacheKey = getCacheKey(_keyOrderLineIds, orderId)

         var orderLineIds = _cache.get(cacheKey)
         if (!orderLineIds || bypassCache) {
            orderLineIds = util
               .getPagesUntilEnd(getOrderLinesPage.bind(null, orderId), 0, config.apiLimit)
               .then(function (orderLines) {
                  cacheOrderLines(orderLines, bypassCache)
                  return util.mapToIds(orderLines)
               })

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, orderLineIds)
         }

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(orderLineIds)
      }

      function getLocalOrderLines() {
         return $q.resolve(_localStorage.get(_keyLocalOrderLines) || [])
      }

      function getOrderLines(orderId, bypassCache) {
         if (orderId === _localOrder.id) {
            return getLocalOrderLines()
         }
         return getOrderLineIds(orderId, bypassCache).then(getOrderLinesByIds)
      }

      //================================================================================
      // OrderLines: Update
      //================================================================================

      function updateLocalOrderLine(orderLineId, orderLineData) {
         return getLocalOrderLines().then(function (localOrderLines) {
            var localOrderLine = util.findById(localOrderLines, orderLineId)
            angular.extend(localOrderLine, orderLineData)
            _localStorage.put(_keyLocalOrderLines, localOrderLines)
            return $q.resolve({'order-line': localOrderLine})
         })
      }

      function updateOrderLine(orderLineId, orderLineData) {
         if (orderLineData.order === _localOrder.id) {
            return updateLocalOrderLine(orderLineId, orderLineData)
         }

         return getOrderLine(orderLineId).then(function (existingOrderLine) {
            var updatedOrderLine = angular.extend({}, existingOrderLine, orderLineData)
            // do not allow changing the id
            orderLineData.id = orderLineId

            // remove read-only properties
            delete updatedOrderLine.price
            delete updatedOrderLine.weight
            delete updatedOrderLine.volume

            return retryOnce(
               AzureAPI['order-line'].save,
               {
                  // Note that the UI does not currently allow any way to increase the per-unit-price on an orderline.
                  allowPriceIncrease: false,
               },
               updatedOrderLine
            )
               .then(util.cleanItem)
               .then(function (response) {
                  var orderLine = response['order-line']
                  cacheOrderLine(orderLine, true)
                  handleOrderLineChangeCacheClearing(response.changes, orderLine.order)
                  return response
               })
               .catch(function (error) {
                  if (
                     error.data &&
                     error.data.errorReasonSlug &&
                     error.data.errorReasonSlug === 'requiresPriceIncreaseConfirmation'
                  ) {
                     error.requiresPriceIncreaseConfirmation = true
                     error.doNotShowErrorAlert = true

                     error.existingOrderLineQuantity = existingOrderLine['quantity-ordered']
                     error.quantityToAdd = updatedOrderLine['quantity-ordered'] - existingOrderLine['quantity-ordered']

                     return $q.reject(error)
                  } else {
                     return maybeTranslateError(error)
                  }
               })
         })
      }

      function syncLocalOrderLinesToOrderId(orderId) {
         return getLocalOrderLines().then(function (localOrderLines) {
            if (!localOrderLines.length) {
               return $q.resolve()
            }

            return $q
               .mapSettled(localOrderLines, function (line) {
                  return createOrderLine(orderId, line['packaged-product'], line['quantity-ordered'])
               })
               .then(function (results) {
                  _localStorage.put(_keyLocalOrderLines, [])
                  return results
               })
         })
      }

      //================================================================================
      // OrderLines: Delete
      //================================================================================

      function deleteLocalOrderLine(orderLineId) {
         return getLocalOrderLines().then(function (localOrderLines) {
            util.removeById(localOrderLines, orderLineId)
            _localStorage.put(_keyLocalOrderLines, localOrderLines)
            return $q.resolve({})
         })
      }

      function deleteOrderLine(orderId, orderLineId) {
         if (orderId === _localOrder.id) {
            return deleteLocalOrderLine(orderLineId)
         }
         return getOrderLine(orderLineId).then(function (orderLine) {
            return retryOnce(AzureAPI['order-line'].delete, {
               id: orderLineId,
            })
               .then(util.cleanItem)
               .then(function (response) {
                  cacheRemoveOrderLine(orderLineId)
                  cacheRemoveOrderLineId(orderLine.order, orderLineId)

                  handleOrderLineChangeCacheClearing(response.changes, orderLine.order)

                  return response
               })
         })
      }

      //================================================================================
      // Promo Codes
      //================================================================================

      function localAddPromoCode(promoCodeToAdd) {
         _localStorage.put(_keyLocalPromoCode, {
            code: promoCodeToAdd,
         })

         return $q.resolve()
      }

      function localGetPromoCode() {
         return $q.resolve(_localStorage.get(_keyLocalPromoCode))
      }

      function localRemovePromoCode() {
         return $q.resolve(_localStorage.remove(_keyLocalPromoCode))
      }

      function getPromoCodeById(promoCodeId, orderId, bypassCache) {
         var cacheKey = getCacheKey(_keyPromoCode, orderId, promoCodeId)

         var promoCode = _cache.get(cacheKey)

         if (!promoCode || bypassCache) {
            promoCode = retryOnce(AzureAPI['order-promo-code'].get, {
               id: promoCodeId,
            }).then(util.cleanItem)

            // cache the promise, which is replaced with the resolved value by CacheFactory option storeOnResolve
            _cache.put(cacheKey, promoCode)
         }
         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(promoCode)
      }

      function addPromoCodeToOrder(orderId, promoCode) {
         var promoCodeRequest = retryOnce(AzureAPI['order-promo-code'].create, {
            code: promoCode,
            orderId: orderId,
         })
            .then(util.cleanItem)
            .then(function (orderPromoCode) {
               _cache.put(getCacheKey(_keyPromoCode, orderId, orderPromoCode.id), orderPromoCode)

               // Here we're removing the order from the cache so that it's fetched fresh from the API.
               // This is needed to prevent the order's `orderPromoCodeIds` parameter from going stale.
               _cache.remove(getCacheKey(_keyOrder, orderId))

               return orderPromoCode
            })

         // $q.resolve ensures that this always returns a promise that resolves with the expected object
         return $q.resolve(promoCodeRequest)
      }

      function removePromoCode(orderId, promoCodeId) {
         return $q
            .all([
               retryOnce(AzureAPI['order-promo-code'].delete, {
                  id: promoCodeId,
               }),
               getOrder(orderId),
            ])
            .then(function (responses) {
               // Here we're clearing the promo code cache for this order, which triggers them to be fetched fresh from
               // the API. This is to prevent backend-set state from going stale (such as the "invalid reason description").
               responses[1].orderPromoCodeIds.forEach(function (promoCodeId) {
                  _cache.remove(getCacheKey(_keyPromoCode, orderId, promoCodeId))
               })

               // Here we're removing the order from the cache so that it's fetched fresh from the API.
               // This is needed to prevent the order's `orderPromoCodeIds` parameter from going stale.
               _cache.remove(getCacheKey(_keyOrder, orderId))
            })
      }

      //================================================================================

      return {
         // Orders
         createOrder: createOrder,
         getOrdersByIds: getOrdersByIds,
         getOrder: getOrder,
         getOrderFees: getOrderFees,
         getOrdersByDropTrip: getOrdersByDropTrip,
         getOrderCountByDropTrip: getOrderCountByDropTrip,
         getParcelCarrierEstimates: getParcelCarrierEstimates,
         getSingleD2hQualifyingEstimate: getSingleD2hQualifyingEstimate,
         getD2hQualifyingEstimates: getD2hQualifyingEstimates,
         getUnresolvedOrders: getUnresolvedOrders,
         getResolvedOrders: getResolvedOrders,
         getResolvedOrderCount: getResolvedOrderCount,
         getActiveOrderId: getActiveOrderId,
         getActiveOrder: getActiveOrder,
         setActiveOrderId: setActiveOrderId,
         tryUpdateOrder: tryUpdateOrder,
         updateOrder: updateOrder,
         lateUpdateToD2hDelivery: lateUpdateToD2hDelivery,
         deleteOrder: deleteOrder,
         getLocalOrderId: getLocalOrderId,
         getLocalLastDestination: getLocalLastDestination,
         getLocalLastPaymentMethodId: getLocalLastPaymentMethodId,

         // Order Statuses
         statuses: _statuses,
         resolvedStatusStrings: _resolvedStatusStrings,
         unresolvedStatusStrings: _unresolvedStatusStrings,

         // OrderLines
         createOrderLine: createOrderLine,
         getOrderLines: getOrderLines,
         getLocalOrderLines: getLocalOrderLines,
         updateOrderLine: updateOrderLine,
         deleteOrderLine: deleteOrderLine,
         syncLocalOrderLinesToOrderId: syncLocalOrderLinesToOrderId,

         // PromoCode
         localAddPromoCode: localAddPromoCode,
         localGetPromoCode: localGetPromoCode,
         localRemovePromoCode: localRemovePromoCode,
         getPromoCodeById: getPromoCodeById,
         addPromoCodeToOrder: addPromoCodeToOrder,
         removePromoCode: removePromoCode,

         // cache clearing
         clearLocalLastPaymentMethodId: clearLocalLastPaymentMethodId,
         clearUserCache: cacheRemoveUser,
         clearOrder: cacheRemoveOrder,
         clear: _cache.removeAll,
      }
   }
})(angular)
